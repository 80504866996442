import "@fontsource/source-sans-pro/400.css";
import "@fontsource/source-sans-pro/600.css";

import "./src/styles/global.scss";

export const onInitialClientRender = () => {
  if (history.scrollRestoration) {
    history.scrollRestoration = "manual";
  }
};
